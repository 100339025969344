import { SmsSettings } from '@myneva-portals/projects/src/components';
import {
  RestUsersService,
  ITemplateDetails,
  ITemplateRequestDetails,
} from '@myneva-portals/services/src/services';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { defaultErrorToast } from '../../components/sections/static-data/default-error-toast';
import { showToast } from 'front-components/src/components/Modals/toast-error-modal';
import { useQuery, useMutation } from 'react-query';
import { useDropzone } from 'react-dropzone';

export const SmsSettingsView: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const url = process.env.WEB_GATEWAY_URL + '/core/settings/';
  const userService = new RestUsersService(url);
  const { breadcrumbs } = useSelector(navigationSelector);

  const customBreadCrumbConfig = [
    {
      breadcrumbName: t('SMS_SETTINGS'),
      path: '/sms-settings',
    },
  ];

  const smsTemplate = useQuery(
    'getSmsTemplatesQuery',
    () =>
      userService.getSmsTemplates().then((data: ITemplateDetails[]) => data),
    {
      onError: () => {
        showToast(toastConfig);
      },
    }
  );

  const { mutate: addOrEditSmsTemplate } = useMutation(
    (template: ITemplateRequestDetails) =>
      userService.addOrEditSmsTemplate(template).then(() => {
        smsTemplate.refetch();
      }),
    {
      onError: () => {
        showToast(toastConfig);
      },
    }
  );

  return (
    <SmsSettings
      translation={t}
      breadcrumbs={breadcrumbsUtils.getBreadcrumbs(
        breadcrumbs,
        customBreadCrumbConfig
      )}
      smsTemplateData={smsTemplate.data}
      addOrEditSmsTemplate={addOrEditSmsTemplate}
      useDropzone={useDropzone}
    />
  );
};
