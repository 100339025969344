import * as React from 'react';

import 'front-components/src/styles/colorPallete.css';
import 'front-components/src/styles/fonts.css';
import 'front-components/src/styles/spacing.css';

import { PortalLayout } from 'front-components/src/components';

import { AdminMenu } from '../components/asside-menu/asside-menu';
import { PortalHeader } from '../components/portal-header/portal-header';
import { SmsSettingsView } from '../content/sms-settings/sms-settings';

const SmsSettingsPage = () => {
  const asside = <AdminMenu />;
  const content = <SmsSettingsView />;

  return (
    <main>
      <title>Sms Settings Page</title>
      <PortalLayout header={<PortalHeader />} asside={asside}>
        {content}
      </PortalLayout>
    </main>
  );
};

export default SmsSettingsPage;
